import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from '../../assets/img/logoBlanco.png';
import LinkedIn from '../../assets/img/linkedin.svg';
import Twitter from '../../assets/img/twitter_x.svg';
import Instagram from '../../assets/img/instagram.svg';
import './Footer.css';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
            <a href="https://www.linkedin.com/company/creative-network-live/" target="_blank"><img src={LinkedIn} alt="LinkedIn" /></a>
            <a href="https://twitter.com/cnl_btc21" target="_blank"><img src={Twitter} alt="Twitter" /></a>
            <a href="https://www.instagram.com/creativenetwork_21" target="_blank"><img src={Instagram} alt="Instagram" /></a>
            </div>
            <p>Creative Network &copy; 2024</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}