import React from 'react';

import { Helmet } from 'react-helmet';

import { Portfolio } from '../Portfolio/components/Portfolio';
//import { LogoSlider } from './components/LogoSlider';
import { Contact } from '../../contact/Contact';
import { AuroraBackgroundProvider } from '@nauverse/react-aurora-background';
import { NavBar } from '../../navbar/NavBar';

export const PortfolioPage = () => {
    return (
        <>
            <Helmet>
                <title>Showcasing Creative Excellence: Our Portfolio | Creative Network</title>
                <meta name="description" content="Explore Creative Network's portfolio to see our innovative work in design, social media, motion graphics, and 3D. Discover how our creative solutions drive success and bring ideas to life. Your vision, transformed." />
            </Helmet>
            <NavBar activeLink={'portfolio'} />
            <div className='portfolio-page'>
                <AuroraBackgroundProvider colors={['#1E1E1E', '#000000']} bgColor='#79BAD3'>
                    <Portfolio />
                    <Contact />
                </AuroraBackgroundProvider>


                
            </div>
        </>
    )
}