import React from 'react'

import { Helmet } from 'react-helmet';

import { Contact } from '../../contact/Contact'
import Banner from './components/Banner'
import { AuroraBackgroundProvider } from '@nauverse/react-aurora-background'
import Metrics from './components/Metrics'
import './components/Solutions.css'
import { NavBar } from '../../navbar/NavBar'

// Imágenes en la lista
import UGCsImage from "../../../assets/img/creative studio/UGCs.png"
import MotionImage from "../../../assets/img/creative studio/Motion.png"
import GraphicImage from "../../../assets/img/creative studio/Graphic.png"
import ARImage from "../../../assets/img/creative studio/AR.png"
import d3Image from "../../../assets/img/creative studio/3D.png"
import BannerImage from "../../../assets/img/creative studio/CreativeStudio_Banner.png"

const CreativeStudio = () => {

  var MainList = [
    {
      id: 1,
      title: "UGCs",
      //subtext: "Leverage user-generated content to enhance brand authenticity and engagement.",
      img: UGCsImage, // Ensure you have imported BannerImage
      //uList: [
        //{ id: 1, subtext: "Brand engagement strategies" },
        //{ id: 2, subtext: "Content curation and promotion" },
        //{ id: 3, subtext: "Community building initiatives" },
      //],
    },
    {
      id: 2,
      title: "Motion",
      //subtext: "Elevate your digital presence with captivating motion graphics and animations.",
      img: MotionImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Animated logos and intros" },
        //{ id: 2, subtext: "Explainer videos" },
        //{ id: 3, subtext: "Social media animations" },
      //],
    },
    {
      id: 3,
      title: "Graphics",
      //subtext: "Crafting visually stunning designs that communicate your brand's essence.",
      img: GraphicImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Brand identity and logo design" },
        //{ id: 2, subtext: "Marketing materials" },
        //{ id: 3, subtext: "Packaging design" },
      //],
    },
    {
      id: 4,
      title: "AR / VR",
      //subtext: "Immersive experiences that connect with your audience on a new level.",
      img: ARImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Augmented reality campaigns" },
        //{ id: 2, subtext: "Virtual reality experiences" },
        //{ id: 3, subtext: "Interactive installations" },
      //],
    },
    {
      id: 5,
      title: "3D",
      //subtext: "Bringing your ideas to life with high-quality 3D modeling and rendering services.",
      img: d3Image, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "3D product visualizations" },
        //{ id: 2, subtext: "Architectural rendering" },
        //{ id: 3, subtext: "Character modeling for games and animations" },
      //],
    },
  ];


  return (
    <>
      <Helmet>
        <title>Creative Studio | Creative Network</title>
        <meta name="description" content="Discover Creative Network's Studio: Where innovation meets design. Specializing in UGC, motion graphics, AR/VR, and 3D modeling, we transform your digital presence into an engaging experience. Explore our services today!" />
      </Helmet>
      <NavBar activeLink={'creative'} />
      <div>
        <AuroraBackgroundProvider colors={['#1E1E1E', '#000000']} bgColor='#79BAD3'>

          <Banner
            BannerImage={BannerImage}
            BannerTitle={"Creative Studio"}
            BannerSubtext={"Comprehensive Design Solutions to Boost Your Visual Identity."} />

          {/* 
          
          Título default = "Metrics We Optimize"
          valores default = XlColumn = 2, LgColumn = 4, MdColumn = 4. <-- Valores de bootstrap
          
          Propiedades editables = Title, MainList, XlColumn, LgColumn, MdColumn
          
          Propiedades de MainList:
            id, title, img, subtext, uList
            img y uList son las únicas opcionales


          */}
          <Metrics MainList={MainList} Title={"Services We Offer"} XlColumn={2} LgColumn={4} MdColumn={4} />
          
        </AuroraBackgroundProvider>
        <Contact />
      </div>
    </>
  )
}

export default CreativeStudio