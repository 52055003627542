import React from 'react'

import { Helmet } from 'react-helmet';

import { Contact } from '../../contact/Contact'
import Banner from './components/Banner'
import { AuroraBackgroundProvider } from '@nauverse/react-aurora-background'
import Metrics from './components/Metrics'
import './components/Solutions.css'
import { NavBar } from '../../navbar/NavBar'

import SEOImage from "../../../assets/img/organic growth/SEO.png"
import MarketingImage from "../../../assets/img/organic growth/Marketing.png"
import MediaImage from "../../../assets/img/organic growth/Media.png"
import EmailImage from "../../../assets/img/organic growth/Email.png"
import BannerImage from "../../../assets/img/organic growth/OrganicGrowth_Banner.png"

const OrganicGrowth = () => {

  var MainList = [
    {
      id: 1,
      title: "SEO",
      //subtext: "Enhance your website's visibility on search engines through effective SEO strategies.",
      img: SEOImage, // Ensure you have imported BannerImage
      //uList: [
        //{ id: 1, subtext: "Keyword research and optimization" },
        //{ id: 2, subtext: "On-page SEO and content quality" },
        //{ id: 3, subtext: "Backlink strategy and domain authority" },
      //],
    },
    {
      id: 2,
      title: "Marketing",
      //subtext: "Drive engagement and retention with valuable and relevant content.",
      img: MarketingImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Blog posts and articles" },
        //{ id: 2, subtext: "Infographics and visual content" },
        //{ id: 3, subtext: "Video marketing and webinars" },
      //],
    },
    {
      id: 3,
      title: "Media",
      //subtext: "Connect with your audience and build community through social media platforms.",
      img: MediaImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Regular posts and updates" },
        //{ id: 2, subtext: "Engagement through comments and messages" },
        //{ id: 3, subtext: "Social media campaigns and contests" },
      //],
    },
    {
      id: 4,
      title: "Email",
      //subtext: "Keep your audience informed and engaged with targeted email campaigns.",
      img: EmailImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Newsletter subscriptions" },
        //{ id: 2, subtext: "Promotional emails and exclusive offers" },
        //{ id: 3, subtext: "Personalized content and segmentation" },
      //],
    }
  ];


  return (
    <>
      <Helmet>
        <title>Organic Growth Strategies for Digital Success | Creative Network</title>
        <meta name="description" content="Unlock the full potential of your brand with Creative Network's Organic Growth services. Specializing in SEO, content marketing, social media, and email campaigns, we craft bespoke strategies to enhance your digital footprint and engage your audience." />
      </Helmet>
      <NavBar activeLink={'organic'} />
      <div>
        <AuroraBackgroundProvider colors={['#1E1E1E', '#000000']} bgColor='#79BAD3'>

          <Banner
            BannerImage={BannerImage}
            BannerTitle={"Organic Growth"}
            BannerSubtext={"Effective Marketing Strategies for Sustainable Growth."} />

          <Metrics MainList={MainList} Title={"Services We Offer"} />
          
        </AuroraBackgroundProvider>
        <Contact />
      </div>
    </>
  )
}

export default OrganicGrowth