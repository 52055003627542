import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import './LogoSlider.css';

import MyNode from '../../../../assets/img/logos/MyNode_White.svg';
import TBI from '../../../../assets/img/logos/TBI_White.svg';
import TBC from '../../../../assets/img/logos/TBC_White.svg';
import SHAmory from '../../../../assets/img/logos/Shamory_White.svg';
import SeedSigner from '../../../../assets/img/logos/Seed Signer_White.svg';
import RoninDojo from '../../../../assets/img/logos/RoninDojo_White.svg';
import BPark from '../../../../assets/img/logos/Bitcoin Park_White.svg';
import Luxor from '../../../../assets/img/logos/Luxor_White.svg';
import AmberApp from '../../../../assets/img/logos/Amber App_White.svg';
import FF from '../../../../assets/img/logos/Freedom Files_White.svg';
import BCB from '../../../../assets/img/logos/Blue Collar Bitcoin_White.svg';
import SimplyBitcoin from '../../../../assets/img/logos/Simply Bitcoin_White.svg';
import MassAdoption from '../../../../assets/img/logos/Mass Adoption_White.svg';
import Henge from '../../../../assets/img/logos/Henge_White.svg';
import NeutronPay from '../../../../assets/img/logos/NeutronoPay_White.svg';
import POD from '../../../../assets/img/logos/POD256_White.svg';
import OPA from '../../../../assets/img/logos/Orange Pill App_White.svg';
import LightningKoffee from '../../../../assets/img/logos/Lightning Koffee_White.svg';
import HodlHodl from '../../../../assets/img/logos/Lightning Koffee_White.svg';



export const LogoSlider = () => {
    return (
        <section className="logo-slider" id="logoslider">
            <Container>
                <Row className="slide-track">
                    <Col size={12} className="slide" >
                        <a href="https://neutronpay.com/" target="_blank" rel="noopener noreferrer">
                            <img src={NeutronPay} alt="NeutronPay" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://www.linkedin.com/company/hengeco/" target="_blank" rel="noopener noreferrer">
                            <img src={Henge} alt="Henge" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://www.mynodebtc.com/" target="_blank" rel="noopener noreferrer">
                            <img src={MyNode} alt="MYNODE" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://thebitcoincompany.com/" target="_blank" rel="noopener noreferrer">
                            <img src={TBC} alt="The Bitcoin Company" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://luxor.tech/" target="_blank" rel="noopener noreferrer">
                            <img src={Luxor} alt="Luxor" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://freedomfiles.co/" target="_blank" rel="noopener noreferrer">
                            <img src={FF} alt="Freedom Files" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://seedsigner.com/" target="_blank" rel="noopener noreferrer">
                            <img src={SeedSigner} alt="SeedSigner" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://ronindojo.io/" target="_blank" rel="noopener noreferrer">
                            <img src={RoninDojo} alt="RoninDojo" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://bitcoinpark.com/" target="_blank" rel="noopener noreferrer">
                            <img src={BPark} alt="Bitcoin Park" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://www.theorangepillapp.com/" target="_blank" rel="noopener noreferrer">
                            <img src={OPA} alt="Orange Pill App" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://amber.app/" target="_blank" rel="noopener noreferrer">
                            <img src={AmberApp} alt="Amber App" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://beefinitiative.com/" target="_blank" rel="noopener noreferrer">
                            <img src={TBI} alt="The Beef Initiative" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://lightningkoffee.com/" target="_blank" rel="noopener noreferrer">
                            <img src={LightningKoffee} alt="Lightning Koffee" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://shamory.com/" target="_blank" rel="noopener noreferrer">
                            <img src={SHAmory} alt="SHAmory" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://www.bluecollarbitcoin.io/" target="_blank" rel="noopener noreferrer">
                            <img src={BCB} alt="Blue Collar Bitcoin" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://hodlhodl.com/" target="_blank" rel="noopener noreferrer">
                            <img src={HodlHodl} alt="HodlHodl" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://www.youtube.com/c/simplybitcoin" target="_blank" rel="noopener noreferrer">
                            <img src={SimplyBitcoin} alt="Simply Bitcoin" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://www.massadoption.net/" target="_blank" rel="noopener noreferrer">
                            <img src={MassAdoption} alt="Mass Adoption" />
                        </a>
                    </Col>
                    <Col size={12} className="slide" >
                        <a href="https://podcasters.spotify.com/pod/show/pod256" target="_blank" rel="noopener noreferrer">
                            <img src={POD} alt="POD256" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}