import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Footer } from './components/footer/Footer';
import { Home } from './components/pages/Home/Home';

// Solutions 
import CreativeStudio from './components/pages/Solutions/CreativeStudio';
import Youtube from './components/pages/Solutions/Youtube';
import OrganicGrowth from './components/pages/Solutions/OrganicGrowth';
import UxUi from './components/pages/Solutions/UxUi';

// Portfolio
import { PortfolioPage } from './components/pages/Portfolio/PortfolioPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />

          {/* Solution Routes */}
          <Route path="/creative" element={<CreativeStudio />} />
          <Route path="/youtube" element={<Youtube />} />
          <Route path="/organic-growth" element={<OrganicGrowth />} />
          <Route path="/ux-ui" element={<UxUi />} />

          <Route path="/portfolio" element={<PortfolioPage />} />

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;