import React from 'react'

import { Helmet } from 'react-helmet';

import { Contact } from '../../contact/Contact'
import Banner from './components/Banner'
import { AuroraBackgroundProvider } from '@nauverse/react-aurora-background'
import Metrics from './components/Metrics'
import './components/Solutions.css'

import ResearchImage from "../../../assets/img/ux ui/Research.png"
import OptimizationImage from "../../../assets/img/ux ui/Optimization.png"
import DesignImage from "../../../assets/img/ux ui/UX_Design.png"
import StrategyImage from "../../../assets/img/ux ui/Strategy.png"
import BannerImage from "../../../assets/img/ux ui/UxUi_Banner.png"
import { NavBar } from '../../navbar/NavBar'


const UxUi = () => {

  var MainList = [
    {
      id: 1,
      title: "Research",
      //subtext: "Understand your users' needs and behaviors to create intuitive and user-centered designs.",
      img: ResearchImage, // Ensure you have imported BannerImage or replace with actual image path
      //uList: [
        //{ id: 1, subtext: "Surveys and user interviews" },
        //{ id: 2, subtext: "Persona development" },
        //{ id: 3, subtext: "Usability testing" },
      //],
    },
    {
      id: 2,
      title: "Design",
      //subtext: "Craft visually appealing designs that are easy to use and align with your brand identity.",
      img: DesignImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Visual design and branding" },
        //{ id: 2, subtext: "Responsive and adaptive design" },
        //{ id: 3, subtext: "Prototyping and wireframing" },
      //],
    },
    {
      id: 3,
      title: "Optimization",
      //subtext: "Ensure a seamless and enjoyable experience for your users to encourage retention.",
      img: OptimizationImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Website navigation and accessibility" },
        //{ id: 2, subtext: "Page load speed and mobile optimization" },
        //{ id: 3, subtext: "User feedback and continuous improvement" },
      //]
    },
    {
      id: 4,
      title: "Strategy",
      //subtext: "Develop a comprehensive UX strategy that aligns with your business goals and user expectations.",
      img: StrategyImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Customer journey mapping" },
        //{ id: 2, subtext: "Content strategy and architecture" },
        //{ id: 3, subtext: "Accessibility and inclusivity planning" },
      //],
    }
  ];

  return (
    <>
      <Helmet>
        <title>Expert UX/UI Design Services | Creative Network</title>
        <meta name="description" content="Transform your digital products with Creative Network's UX/UI Design services. From user research to design optimization, our comprehensive approach ensures intuitive, user-centered solutions that drive growth. Discover our workflow and elevate your user experience today." />
      </Helmet>
      <NavBar activeLink={'ux-ui'} />
      <div>
        <AuroraBackgroundProvider colors={['#1E1E1E', '#000000']} bgColor='#79BAD3'>

          <Banner
            BannerImage={BannerImage}
            BannerTitle={"UX/UI"}
            BannerSubtext={"Seamless UI/UX Designs for Web and Mobile Apps."} />

          <Metrics MainList={MainList} Title={"Our Workflow"} />
          
        </AuroraBackgroundProvider>
        <Contact />
      </div>
    </>
  )
}

export default UxUi