import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowLeft from '../../../../assets/img/arrow1.svg';
import ArrowRight from '../../../../assets/img/arrow2.svg';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ 
                ...style, 
                display: "block", 
                background: `url(${ArrowRight}) center center / cover no-repeat`,
                right: '10px',
                width: '50px',
                height: '50px',
                zIndex: 25
            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ 
                ...style, 
                display: "block", 
                background: `url(${ArrowLeft}) center center / cover no-repeat`,
                left: '10px',
                width: '50px',
                height: '50px',
                zIndex: 25
            }}
            onClick={onClick}
        />
    );
}

const Metrics = ({ Title, MainList }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [pauseAutoplay, setPauseAutoplay] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Adjusted interaction handler
    const handleInteraction = () => {
        if (!pauseAutoplay) {
            setPauseAutoplay(true);
            setTimeout(() => {
                setPauseAutoplay(false);
            }, 5000); // Pause for 5 seconds then resume
        }
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow onClick={handleInteraction} />,
        prevArrow: <SamplePrevArrow onClick={handleInteraction} />,
        autoplay: !pauseAutoplay,
        autoplaySpeed: 5000,
        pauseOnHover: true, // Consider pausing on hover to give users more control
        afterChange: () => setPauseAutoplay(false), // Ensure autoplay resumes after manual change
    };

    return (
        <section className='metrics-section'>
            <Container>
                <h2 className='d-flex justify-content-center mb-5'>{Title || "Metrics We Optimize"}</h2>
                {windowWidth < 768 ? (
                    <Slider {...sliderSettings}>
                        {MainList.map((Metric, index) => (
                            <div key={index} className="slide-content">
                                <h3>{Metric.title}</h3>
                                {Metric.img && (
                                    <div className="d-flex justify-content-center">
                                        <img src={Metric.img} alt="" style={{ maxWidth: '60%', height: 'auto' }} />
                                    </div>
                                )}
                                <p>{Metric.subtext}</p>
                                {Metric.uList && (
                                    <ul>
                                        {Metric.uList.map((ListItem) => (
                                            <li key={ListItem.id}>{ListItem.subtext}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <Row className='justify-content-center'>
                        {MainList.map((Metric, index) => (
                            <Col key={index} className='metric-col' xl={2} lg={3} md={4} sm={6}>
                                <h3>{Metric.title}</h3>
                                {Metric.img && (
                                    <div className="text-center">
                                        <img src={Metric.img} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                                    </div>
                                )}
                                <p>{Metric.subtext}</p>
                                {Metric.uList && (
                                    <ul>
                                        {Metric.uList.map((ListItem) => (
                                            <li key={ListItem.id}>{ListItem.subtext}</li>
                                        ))}
                                    </ul>
                                )}
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>
        </section>
    );
};

export default Metrics;
