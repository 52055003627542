import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { PopupButton } from "react-calendly";


const Banner = ({ BannerImage, BannerTitle, BannerSubtext }) => {
    return (
        <section className="banner solutions-banner" id='banner'>
            <Container>
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={7} className="d-flex align-items-center justify-items-center">
                        <div>
                            <p>{BannerTitle}</p>
                            <p className='solution-subtext'>{BannerSubtext}</p>
                            <PopupButton
                                className="sales"
                                url="https://calendly.com/creativenetwork/30min"
                                rootElement={document.getElementById("root")}
                                text="BOOK A CALL"
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <div className={false ? "animate__animated animate__zoomIn" : ""}>
                            <img src={BannerImage} alt="Welcome" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Banner

