import React from 'react';

import { Helmet } from 'react-helmet';

import { Banner } from './components/Banner';
import { LogoSlider } from './components/LogoSlider';
import { Contact } from '../../contact/Contact';
import { AuroraBackgroundProvider } from '@nauverse/react-aurora-background';

import { NavBar } from '../../navbar/NavBar';

export const Home = () => {
  return (
  <>
    <Helmet>
      <title>Creative Network Live</title>
      <meta name="description" content="Elevate your brand with Creative Network. From bespoke marketing strategies to cutting-edge design, we provide holistic creative solutions. Let's build your success story together!" />
    </Helmet>
    <NavBar activeLink={"home"} />
    <div className='home-page'>
      <AuroraBackgroundProvider colors={['#1E1E1E', '#000000']} bgColor='#79BAD3'>
        <Banner />
        <LogoSlider />
      </AuroraBackgroundProvider>

      <Contact />
    </div>
  </>
  )
}