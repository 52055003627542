import React from 'react'

import { Helmet } from 'react-helmet';

import { Contact } from '../../contact/Contact'
import Banner from './components/Banner'
import { AuroraBackgroundProvider } from '@nauverse/react-aurora-background'
import Metrics from './components/Metrics'
import './components/Solutions.css'
import VisibilityImage from "../../../assets/img/youtube/Visibility.png"
import RetentionImage from "../../../assets/img/youtube/Retention.png"
import GrowthImage from "../../../assets/img/youtube/Growth.png"
import BannerImage from "../../../assets/img/youtube/YouTube_Banner.png"
import { NavBar } from '../../navbar/NavBar'

const YouTube = () => {

  var MainList = [
    {
      id: 1,
      title: "Visibility",
      //subtext: "Increasing your channel's visibility is key to attracting new viewers and subscribers.",
      img: VisibilityImage, // Ensure you have imported VisibilityImage or replace with actual image path
      //uList: [
        //{ id: 1, subtext: "Optimizing video titles and descriptions for search" },
        //{ id: 2, subtext: "Effective use of tags to improve discoverability" },
        //{ id: 3, subtext: "Creating engaging thumbnails to stand out" },
      //],
    },
    {
      id: 2,
      title: "Retention",
      //subtext: "Keeping viewers engaged and returning to your channel is essential for long-term success.",
      img: RetentionImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Developing content that encourages viewer interaction" },
        //{ id: 2, subtext: "Utilizing analytics to understand viewer preferences" },
        //{ id: 3, subtext: "Creating series and playlists to increase watch time" },
      //],
    },
    {
      id: 3,
      title: "Growth",
      //subtext: "Sustainable growth involves expanding your viewer base and increasing channel engagement.",
      img: GrowthImage, // Placeholder or path to an actual image
      //uList: [
        //{ id: 1, subtext: "Collaborating with other creators to reach new audiences" },
        //{ id: 2, subtext: "Promoting your channel across social media platforms" },
        //{ id: 3, subtext: "Regularly uploading content to keep your audience engaged" },
      //],
    }
  ];


  return (
    <>
      <Helmet>
        <title>Maximize Your YouTube Channel's Potential | Creative Network</title>
        <meta name="description" content="Boost your YouTube channel with Creative Network's specialized services in Visibility, Retention, and Growth. From SEO-optimized titles to strategic content planning, we help you attract and engage a wider audience. Elevate your channel today!" />
      </Helmet>
      <NavBar activeLink={'youtube'} />
      <div>
        <AuroraBackgroundProvider colors={['#1E1E1E', '#000000']} bgColor='#79BAD3'>

          <Banner
            BannerImage={BannerImage}
            BannerTitle={"YouTube"}
            BannerSubtext={"Professional Video Editing for Every Content Type."} />

          <Metrics MainList={MainList} />
          
        </AuroraBackgroundProvider>
        <Contact />
      </div>
    </>
  )
}

export default YouTube