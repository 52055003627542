import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import bannerIlustration from '../../../../assets/img/Home_Banner.png';
import { PopupButton } from "react-calendly";
import './Banner.css';

export const Banner = () => {
    return (
        <section className="banner" id="home">
            <Container>
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <div className={false ? "animate__animated animate__fadeIn" : ""}>

                            <span className="tagline">Welcome to Creative Network</span>
                            <p>Design<br></br>For<br></br>Everyone</p>
                            <PopupButton
                                className="sales"
                                url="https://calendly.com/creativenetwork/30min"
                                rootElement={document.getElementById("root")}
                                text="BOOK A CALL"
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <div className={false ? "animate__animated animate__zoomIn" : ""}>
                            <img src={bannerIlustration} alt="Welcome" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}