import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import logo from '../../assets/img/logoBlanco.png';
import LinkedIn from '../../assets/img/linkedin.svg';
import Twitter from '../../assets/img/twitter_x.svg';
import Instagram from '../../assets/img/instagram.svg';
import { HashLink } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import './NavBar.css';

export const NavBar = ({ activeLink }) => {
    const [scrolled, setScrolled] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const setCollapsedBackground = () => {
        setCollapsed(!collapsed);
        if (!scrolled) {
            setScrolled(true);
        } else {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }

        }
    }

    const checkActiveLink = (currentLink) => {
        console.log(activeLink)
        return activeLink === currentLink ? 'active navbar-link' : 'navbar-link'
    }

    const checkActiveSolutionLink = (currentLink) => {
        console.log(activeLink)
        return activeLink === currentLink ? 'dropdown-item-link active-link' : 'dropdown-item-link'
    }

    return (
        <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
            <Container>
                <Navbar.Brand href="#home">
                    <img src={logo} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle className="navbar-toggler" aria-controls="basic-navbar-nav" onClick={() => setCollapsedBackground()}>
                    {collapsed ? (<svg xmlns="http://www.w3.org/2000/svg" height="2rem" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#64b783">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" height="2rem" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#7bbada">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    )}
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={HashLink} to="/#home" className={checkActiveLink("home")} >Home</Nav.Link>
                        <NavDropdown title="Solutions" id="nav-dropdown-services" className='navbar-link'>
                            <NavDropdown.Item as={HashLink} to="/youtube#banner" className={checkActiveSolutionLink('youtube')}>
                                Youtube
                            </NavDropdown.Item>
                            <NavDropdown.Item as={HashLink} to="/creative#banner" className={checkActiveSolutionLink('creative')}>
                                Creative Studio
                            </NavDropdown.Item>
                            <NavDropdown.Item as={HashLink} to="/organic-growth#banner" className={checkActiveSolutionLink('organic')}>
                                Organic Growth
                            </NavDropdown.Item>
                            <NavDropdown.Item as={HashLink} to="/ux-ui#banner" className={checkActiveSolutionLink('ux-ui')}>
                                UX/UI
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={HashLink} to="/portfolio#portfolio" className={checkActiveLink("portfolio")} >Portfolio</Nav.Link>

                    </Nav>
                    <span className="navbar_text">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/company/creative-network-live/" target="_blank"><img src={LinkedIn} alt="LinkedIn" /></a>
                            <a href="https://twitter.com/cnl_btc21" target="_blank"><img src={Twitter} alt="Twitter" /></a>
                            <a href="https://www.instagram.com/creativenetwork_21" target="_blank"><img src={Instagram} alt="Instagram" /></a>
                        </div>
                        <HashLink to='#contact'>
                            <button className="vvd" onClick={() => console.log('contact')}><span>Contact Us</span></button>
                        </HashLink>
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}